<template>
  <div
    v-if="user"
    class="author d-flex flex-col justify-space-between align-center"
  >
    <div class="d-flex flex-col">
      <v-avatar
        size="40"
      >
        <img :src="user.photo">
      </v-avatar>
      <div class="ml-4">
        <div class="flex flex-column">
          <p class="fw-13-600">
            {{ user.name }}
          </p>
          <p class="fw-11-500 light-gray-text">
            {{ user.email }}
          </p>
        </div>
      </div>
    </div>
    <!--Envelope-->
    <div
      v-if="user.email"
    >
      <a
        class=""
        :href="`mailto:${ user.email }`"
        style="text-decoration:none"
      >
        <v-icon
          size="21"
          color="primary"
        >$envelopeIcon</v-icon>
      </a>
    </div>
  </div>
</template>

<script>
import { TYPES } from '@kickbox/common-util/constants/inno-hub';

export default {
  name: 'UserDisplay',
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innoHubTypes: TYPES
    };
  },
  computed: {
    userLinks() {
      return this.user.links.filter((l) => l.title !== 'slack');
    }
  }
};
</script>

<style scoped>
p {
  margin-bottom: 0 !important;
}
.author {
  height: 50px;
}
</style>
