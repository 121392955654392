<template>
  <div
    v-if="content"
    class="d-flex flex-column detail-page-container ml-12"
  >
    <div class="d-flex content-container mb-10 ql-editor flex-column">
      <v-row>
        <v-col cols="7">
          <!--Title-->
          <h1 class="mb-2 fw-30-600">
            {{ content.title }}
          </h1>
          <p class="mb-4">
            {{ content.summary }}
          </p>
          <!--Tags-->
          <v-chip
            v-for="tag in content.tags"
            :key="tag.title"
            class="justify-center mt-2 mb-6 mr-2 no-hover"
            outlined
            small
            label
          >
            <span class="fw-12-600 light-gray-text">{{ tag.title }}</span>
          </v-chip>
        </v-col>
      </v-row>
      <div class="d-flex">
        <!--Left column-->
        <div class="left mb-4">
          <div>
            <!--Image-->
            <img
              v-if="!content.pdfUrl"
              class="mb-4 service-image"
              width="100%"
              :src="content.cardImage"
            >
            <v-card
              v-else
              class="pa-6"
            >
              <pdf
                :src="content.pdfUrl"
                :page="1"
              />
              <v-row
                justify="center"
                class="mt-3"
              >
                <v-col
                  cols="5"
                  class="pb-0 align-self-center"
                >
                  <span class="fw-16-600">KICKBOX Presentation</span>
                </v-col>
                <v-col
                  cols="7"
                  class="text-right pb-0"
                >
                  <v-btn
                    :href="content.pdfUrl"
                    target="_blank"
                    :height="35"
                    outlined
                    class="pdf-btn"
                  >
                    <span class="blue-text fw-13-700">VIEW PDF</span>
                  </v-btn>
                  <v-btn
                    icon
                    class="ml-3"
                  >
                    <v-icon
                      size="20"
                      @click="downloadPdf"
                    >
                      $downloadIcon
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <!--Description-->
            <span class="fw-16-600 d-block mb-3 mt-8">
              Description
            </span>
            <div
              class="description"
              v-html="$sanitize(content.text, metaSanitizeOptions)"
            />
            <!--Related Services-->
            <div class="related">
              <template v-if="relatedServices && relatedServices.length">
                <p class="mb-4 mt-8 fw-16-600">
                  Related Services
                </p>
                <div class="flex-wrap d-flex">
                  <service-card
                    v-for="service in relatedServices"
                    :key="service.id"
                    :service="service"
                    class="mr-6"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
        <!--Right column-->
        <div class="right d-flex flex-column pl-8">
          <!--Price-->
          <p
            v-if="content.price.trim()"
            class="fw-30-600 mb-4"
          >
            {{ content.price }}
          </p>
          <!--Request Services-->
          <app-button
            v-if="content.downloadLink"
            class="mt-2"
            :href="content.downloadLink"
            target="_blank"
            @click="trackCustomEvent('download_material')"
          >
            Order Now
          </app-button>
          <!--People-->
          <div
            v-if="content.authors.length"
            class="mt-7"
          >
            <p class="fw-16-600 mb-2">
              Any questions?
            </p>
            <p class="fw-13-500 gray-text mb-4">
              We are here to help
            </p>
            <user-display
              v-for="contact in content.authors"
              :key="contact.name"
              :user="contact"
              :type="content.type"
              class="mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueSilentbox from 'vue-silentbox';
import { mapGetters } from 'vuex';
import { InnoHubService } from '@kickbox/common-admin';
import { TYPES } from '@kickbox/common-util/constants/inno-hub';
import { metaSanitizeOptions } from '@kickbox/common-util';
import pdf from 'vue-pdf';
import analyticsService from '@/services/analyticsService';
import ServiceCard from '@/components/innovationShop/cards/ServiceCard';
import UserDisplay from './widgets/UserDisplay';

Vue.use(VueSilentbox);

export default {
  name: 'DetailPage',
  components: {
    UserDisplay,
    ServiceCard,
    pdf
  },
  props: {
    id: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      content: null,
      innoHubTypes: TYPES,
      metaSanitizeOptions,
    };
  },
  computed: {
    ...mapGetters(['innoHubContent', 'services']),
    relatedServices() {
      return this.content.relatedServices && this.content.relatedServices
        .map((service) => this.services.find((item) => item.id === service.id))
        .filter((s) => s);
    }
  },
  watch: {
    async id() {
      this.content = await this.$store.getters.innoHubContentById(this.id);
      this.checkContent();
    }
  },
  async created() {
    if (!this.innoHubContent.length) {
      const innoHubContent = await InnoHubService.getInnoHubContent();
      this.$store.commit('setInnoHubContent', innoHubContent);
    }
    this.content = await this.$store.getters.innoHubContentById(this.id);
    this.checkContent();
    const eventName = this.content.type === TYPES.SERVICE ? 'service_clicked' : 'article_clicked';
    this.trackCustomEvent(eventName);
  },
  methods: {
    // Used to track clicks and page views
    trackCustomEvent(eventName) {
      analyticsService.trackCustomEvent(eventName, {
        title: this.content.title,
      });
    },
    checkContent() {
      if (!this.content) {
        this.$router.push({ name: 'InnovationShop' });
      }
    },
    downloadPdf() {
      fetch(this.content.pdfUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'presentation.pdf';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    }
  },
};
</script>

<style scoped lang="scss">
@import '~quill/dist/quill.core.css';

h1 {
  line-height: 36.5px;
  margin-bottom: 40px;
}

h3 {
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
}

.content-container {
  width: 100vw;
  box-shadow: inset 0 -10px 15px -15px #ccc;
}

.left {
  width: 680px;
}

.right {
  width: 340px;
}

.related {
  width: 100%;
}

.service-image {
  border-radius: 10px;
}

.pdf-btn {
  background: #FFFFFF;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.07), 0 0 30px rgba(0, 0, 0, 0.07) !important;
  border-radius: 6px;
  border-color: white;
}
</style>

<style lang="scss">
.silentbox-item {
  padding-right: 10px !important;
}

.detail-page-container {
  .ql-editor {
    white-space: initial;
  }

  #silentbox-overlay__close-button {
    right: 1rem;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;

    a {
      text-decoration: none !important;
      color: #2b94fc !important;
    }
  }

  .ql-editor {
    ol, ul {
      padding-left: 0.2rem;
    }

    li.ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 3em;
    }

    li {
      margin: 0.1rem 0;
    }
  }
}
</style>
